@import url("https://fonts.googleapis.com/css?family=Oswald:200,300,400,500");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/* Mixins */
/* Keyframes */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: initial;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: initial;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

/* Animation Classes */
.fadeIn {
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: fadeIn;
  -webkit-animation-timing-function: "linear";
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-name: fadeIn;
  animation-timing-function: "linear";
  animation-fill-mode: forwards;
}

.fadeOut {
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: fadeOut;
  -webkit-animation-timing-function: "linear";
  -webkit-animation-fill-mode: forwards;
  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-name: fadeOut;
  animation-timing-function: "linear";
  animation-fill-mode: forwards;
}

.popup {
  transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  max-height: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(41, 65, 76, 0.85);
  font-family: "Oswald", sans-serif;
  color: #fff;
  z-index: 1000;
  overflow: hidden;
}

.popup__wrapper {
  transition: all 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -150%, 0);
  background: url("/images/popup/bg.jpg") no-repeat center center/cover;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow: hidden;
  border-radius: 0 20px 0 0;
}

@media (min-width: 768px) {
  .popup__wrapper {
    overflow: visible;
  }
}

.popup__liberty {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 0;
}

@media (min-width: 768px) {
  .popup__liberty {
    display: block;
  }
}

.popup__heading {
  text-align: center;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .popup__heading {
    text-align: right;
    padding-bottom: 0;
  }
}

.popup__container {
  padding: 20px;
}

.popup__body {
  width: 70%;
  min-width: 255px;
  margin: 0 auto 0;
  position: relative;
  z-index: 100;
}

@media (min-width: 768px) {
  .popup__body {
    margin: -40px auto 0;
  }
}

.popup h1, .popup h2, .popup h3, .popup h4 {
  text-align: center;
  line-height: 1;
  margin-bottom: 10px;
  margin-top: 0;
}

.popup__intro {
  text-transform: uppercase;
  font-weight: 200;
  font-size: 18px;
  text-shadow: 0 2px 1px #286e9b;
  margin: 0;
}

@media (min-width: 768px) {
  .popup__intro {
    font-size: 32px;
    text-shadow: 0 4px 2px #286e9b;
  }
}

.popup__title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 24px;
  text-shadow: 0 2px 1px #286e9b;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .popup__title {
    font-size: 54px;
    text-shadow: 0 4px 2px #286e9b;
  }
}

.popup__subtitle {
  font-weight: 200;
  font-size: 18px;
  text-shadow: 0 2px 1px #286e9b;
}

@media (min-width: 768px) {
  .popup__subtitle {
    font-size: 32px;
    text-shadow: 0 4px 2px #286e9b;
  }
}

.popup__prices {
  font-weight: 200;
  font-size: 16px;
  font-style: italic;
  text-shadow: 0 2px 1px #286e9b;
}

@media (min-width: 768px) {
  .popup__prices {
    font-size: 22px;
    text-shadow: 0 4px 2px #286e9b;
  }
}

.popup__close {
  background: #c56a52;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  left: auto;
  cursor: pointer;
}

@media (min-width: 768px) {
  .popup__close {
    top: -20px;
    right: -20px;
  }
}

.popup__close span {
  position: absolute;
  height: 2px;
  background: #fff;
  width: 20px;
  left: 10px;
  top: 19px;
}

.popup__close span:first-child {
  transform: rotate(45deg);
}

.popup__close span:last-child {
  transform: rotate(-45deg);
}

.popup__form {
  width: 250px;
  margin: auto;
  padding-top: 10px;
}

@media (min-width: 768px) {
  .popup__form {
    padding-top: 20px;
  }
}

.popup__form__group {
  margin-bottom: 10px;
  text-align: center;
}

.popup__form__input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  border: none;
  font-size: 16px;
  font-family: "Oswald", sans-serif;
  font-style: italic;
  font-weight: 200;
  line-height: 50px;
}

@media (min-width: 768px) {
  .popup__form__input {
    height: 50px;
  }
}

.popup__form__button {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  height: 40px;
  padding: 0 30px;
  line-height: 40px;
  background: #c56a52;
  border: none;
  border-radius: 10px;
  width: 150px;
  display: inline-block;
  color: #fff;
  font-size: 24px;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
  margin-top: 5px;
}

.popup__form__button:hover {
  background: #a34830;
}

@media (min-width: 768px) {
  .popup__form__button {
    height: 50px;
    line-height: 50px;
    margin-top: 15px;
  }
}

.popup.-open {
  opacity: 1;
  max-height: 200vh;
  overflow: auto;
}

.popup.-open .popup__wrapper {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0);
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  min-height: 100%;
}

body {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  overflow-x: hidden;
  width: 100vw;
  font-size: 16px;
  background: url("/images/back.jpg") no-repeat center center/cover;
}

a {
  color: #053f7a;
  transition: all 0.3s ease;
}

a:hover {
  color: #3872ad;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Oswald", sans-serif;
}

// Import Modules
@import 'mixins';
//@import 'libraries';
//@import 'modules';
//@import 'components';
@import 'pages';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
    min-height: 100%;
}

body {
    font-family: $mainFont;
    font-weight: 300;
    overflow-x: hidden;
    width: 100vw;
    font-size: $sm;
    background: url('/images/back.jpg') no-repeat center center / cover;
}

a {
    color:$link;
    transition: all $duration $easingLight;

    &:hover{
        color:$linkHover;
        text-decoration: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $mainFont;
}

.popup {
    $p:&;

    transition: opacity .3s $easing;
    max-height: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    left:0;
    bottom: 0;
    right:0;
    background: transparentize(#29414c, .15);
    font-family: $mainFont;
    color: $white;
    z-index: 1000;
    overflow: hidden;

    &__wrapper {
        @include trans($d:.7s);
        opacity: 0;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate3d(-50%, -150%, 0);
        background: url('/images/popup/bg.jpg') no-repeat center center / cover;
        width: 90%;
        max-width: 1000px;
        max-height: 90vh;
        overflow: hidden;
        border-radius: 0 20px 0 0;

        @include mq(sm) {
            overflow: visible;
        }
    }

    &__liberty {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: none;
        z-index: 0;

        @include mq(sm) {
            display: block;
        }
    }

    &__heading {
        text-align: center;
        padding-bottom: 10px;

        @include mq(sm) {
            text-align: right;
            padding-bottom: 0;
        }
    }

    &__container {
        padding: 20px;
    }

    &__body {
        width: 70%;
        min-width: 255px;
        margin: 0 auto 0;
        position: relative;
        z-index: 100;

        @include mq(sm) {
            margin: -40px auto 0;
        }
    }

    h1,h2,h3,h4 {
        text-align: center;
        line-height: 1;
        margin-bottom: 10px;
        margin-top:0;
    }

    &__intro {
        text-transform: uppercase;
        font-weight: 200;
        font-size: 18px;
        text-shadow: 0 2px 1px #286e9b;
        margin: 0;

        @include mq(sm) {
            font-size: 32px;
            text-shadow: 0 4px 2px #286e9b;
        }
    }

    &__title {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 24px;
        text-shadow: 0 2px 1px #286e9b;
        width: 80%;
        margin-left: auto;
        margin-right: auto;

        @include mq(sm) {
            font-size: 54px;
            text-shadow: 0 4px 2px #286e9b;
        }
    }

    &__subtitle {
        font-weight: 200;
        font-size: 18px;
        text-shadow: 0 2px 1px #286e9b;

        @include mq(sm) {
            font-size: 32px;
            text-shadow: 0 4px 2px #286e9b;
        }
    }

    &__prices {
        font-weight: 200;
        font-size: 16px;
        font-style: italic;
        text-shadow: 0 2px 1px #286e9b;

        @include mq(sm) {
            font-size: 22px;
            text-shadow: 0 4px 2px #286e9b;
        }
    }

    &__close {
        background: #c56a52;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        border: none;
        border-radius:50%;
        left: auto;
        cursor: pointer;

        @include mq(sm) {
            top: -20px;
            right: -20px;
        }

        span {
            position: absolute;
            height: 2px;
            background: #fff;
            width: 20px;
            left:10px;
            top:19px;

            &:first-child {
                transform: rotate(45deg);
            }

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }

    &__form {
        width: 250px;
        margin: auto;
        padding-top: 10px;

        @include mq(sm) {
            padding-top: 20px;
        }

        &__group {
            margin-bottom: 10px;
            text-align: center;
        }

        &__input {
            width: 100%;
            height: 35px;
            border-radius: 5px;
            padding: 0 10px;
            border: none;
            font-size: 16px;
            font-family: $mainFont;
            font-style: italic;
            font-weight: 200;
            line-height: 50px;

            @include mq(sm) {
                height: 50px;
            }
        }

        &__button {
            @include trans;
            height: 40px;
            padding: 0 30px;
            line-height: 40px;
            background: #c56a52;
            border: none;
            border-radius: 10px;
            width: 150px;
            display: inline-block;
            color: $white;
            font-size: 24px;
            font-family: $mainFont;
            cursor: pointer;
            margin-top: 5px;

            &:hover {
                background: #c56a52 - #222;
            }

            @include mq(sm) {
                height: 50px;
                line-height: 50px;
                margin-top: 15px;
            }
        }
    }

    &.-open {
        opacity: 1;
        max-height: 200vh;
        overflow: auto;

        #{$p}__wrapper {
            opacity: 1;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}
